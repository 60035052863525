import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.concat.js";
export default {
  name: "Auth",
  data: function data() {
    return {
      // 定义授权课程版本id为0的时候name名称
      authCourseCategoryName: '完整版',
      // 筛选
      filter: [{
        placeholder: '课程名称',
        value: '',
        type: 'select',
        list: []
      }, {
        placeholder: '授权状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '',
        value: '',
        type: 'data',
        list: []
      }],
      postListData: {
        orgId: '',
        state: '',
        pageSize: '',
        pageIndex: '',
        courseId: '',
        startData: '',
        endData: ''
      },
      // 机构Id
      orgId: '',
      // 用户信息
      userInfo: this.Ls.getParseToken("nof_userInfo"),
      // List接口返回表格数据
      tableKey: 1,
      //表格的key
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      // drawer抽屉-数据
      drawer: false,
      //是否显示弹框
      direction: "rtl",
      drawerState: "",
      drawerData: {
        orgId: '',
        //机构Id
        courseId: '',
        //课程Id
        categoryId: '',
        // 授权课程版本
        amount: 0,
        //授权数量
        extra: 0,
        //赠送数量
        time: [],
        startTime: "",
        //开始时间
        endTime: "",
        //结束时间
        state: 1 //状态
      },

      courseList: [],
      // dialog弹框-删除弹框
      delId: "",
      centerDialogVisible: false,
      // 删除弹框显示隐藏

      versionList: [] // 授权课程版本列表
    };
  },
  mounted: function mounted() {
    if (parseInt(this.$route.query.id) === parseInt(this.Ls.getToken("nof_orgId"))) {
      // 路径传过来的机构id和缓存中一致时候，oId两者都行
      this.orgId = this.$route.query.id || this.Ls.getToken("nof_orgId");
      this.drawerData.orgId = this.$route.query.id || this.Ls.getToken("nof_orgId");
    } else {
      // id不一致的时候，以路径值为准
      if (this.$route.query.id) {
        this.orgId = this.$route.query.id;
        this.drawerData.orgId = this.$route.query.id;
      } else {
        this.orgId = this.Ls.getToken("nof_orgId");
        this.drawerData.orgId = this.Ls.getToken("nof_orgId");
      }
    }
    //获取课程数据
    this.initCourse();
    //获取数据
    this.initData();
  },
  methods: {
    // 添加函数
    addFun: function addFun() {
      this.drawerState = "add";
      this.drawer = true;
      this.drawerData.orgId = this.orgId;
      this.versionList = [{
        id: 1,
        name: this.authCourseCategoryName
      }];
    },
    // 初始化数据
    initCourse: function initCourse() {
      var _this = this;
      //getCourse
      this.service.axiosUtils({
        requestName: "getCourseAll",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this.courseList = res.data;
            _this.filter[0].list = res.data;
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    // 初始化数据
    initData: function initData() {
      var _this2 = this;
      this.postListData.pageSize = this.pageSize;
      this.postListData.pageIndex = this.currentPage;
      this.postListData.orgId = this.orgId;
      this.service.axiosUtils({
        requestName: "getAuthList",
        data: this.postListData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.tableData = res.data.data;
            // allTime
            _this2.total = res.data.total;
            _this2.tableData.map(function (item) {
              var start = item.startTime.split("-").join('.');
              var end = item.endTime.split("-").join('.');
              item.allTime = start + '-' + end;
            });
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    // page-页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // page-页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    // table-修改正常/禁用状态
    editState: function editState(row) {
      var _this3 = this;
      var rowState = "";
      if (row.state === 1) {
        rowState = -1;
      } else {
        rowState = 1;
      }
      this.service.axiosUtils({
        requestName: "changeAuthState",
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: rowState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    // table-中操作函数
    detail: function detail(type, val) {
      var _this4 = this;
      if (type === "edit") {
        this.drawerState = "edit";
        this.drawer = true;
        this.drawerData = {
          id: val.id,
          orgId: val.orgId,
          //机构Id
          courseId: val.courseId,
          //课程Id
          categoryId: val.categoryId,
          //授权课程版本id
          amount: val.amount,
          //授权数量
          extra: val.extra,
          //赠送数量
          startTime: val.startTime,
          //开始时间
          endTime: val.endTime,
          //结束时间
          state: val.state,
          //状态
          time: [val.startTime, val.endTime]
        };
        this.getCourseVersion();
      } else if (type === "del") {
        this.centerDialogVisible = true;
        this.delId = val.id;
      } else if (type === 'send') {
        // sendMsgAuth
        this.service.axiosUtils({
          requestName: "sendMsgAuth",
          data: {
            orgId: val.orgId,
            courseId: val.course.id,
            courseName: val.course.courseName
          },
          sucFn: function sucFn(res) {
            if (res.status === 1) {
              _this4.$toast.success(res.message);
            } else {
              _this4.$toast.error(res.message);
            }
          }
        });
      } else {}
    },
    // drawer抽屉
    datePickFun: function datePickFun(val) {
      if (val) {
        this.drawerData.time = val;
        this.drawerData.startTime = this.drawerData.time[0];
        this.drawerData.endTime = this.drawerData.time[1];
      } else {}
    },
    // drawer抽屉-取消函数
    cancelClick: function cancelClick() {
      this.$nextTick(function () {
        this.$refs["form"].resetFields();
      });
      this.drawer = false;
    },
    // drawer抽屉-确定函数
    confirmClick: function confirmClick(formName) {
      var _this5 = this;
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = "";
          if (that.drawerState === "edit") {
            //修改
            url = "editAuth";
          } else {
            //添加
            url = "addAuth";
          }
          _this5.drawerData.startTime = _this5.drawerData.time[0];
          _this5.drawerData.endTime = _this5.drawerData.time[1];
          console.log(_this5.drawerData);
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                that.drawerData = {
                  orgId: 0,
                  //机构Id
                  courseId: 0,
                  //课程Id
                  categoryId: '',
                  //课程版本Id
                  amount: 0,
                  //授权数量
                  extra: 0,
                  //赠送数量
                  startTime: "",
                  //开始时间
                  endTime: "",
                  //结束时间
                  state: 1 //状态
                };

                if (that.drawerState === "add") {
                  that.currentPage = 1;
                }
                that.$nextTick(function () {
                  that.$refs["form"].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // dialog弹框-删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this6 = this;
      //删除当前的授权
      this.service.axiosUtils({
        requestName: "deleteAuth",
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this6.$toast.success(res.message);
            _this6.delId = "";
            _this6.centerDialogVisible = false;
            _this6.initData();
          } else {
            _this6.$toast.error(res.message);
          }
        }
      });
    },
    // 筛选按钮函数
    search: function search(val) {
      var that = this;
      if (this.$common.checkSearchOrAllFilter(val)) {
        var filterData = val;
        this.postListData.courseId = filterData[0].value;
        this.postListData.startDate = filterData[2].value[0];
        this.postListData.endDate = filterData[2].value[1];
        this.postListData.state = filterData[1].value;
      } else {
        this.postListData.courseId = '';
        this.postListData.startDate = '';
        this.postListData.endDate = '';
        this.postListData.state = '';
      }
      that.initData();
    },
    reload: function reload() {
      for (var i = 0; i < this.filter.length; i++) {
        this.filter[i].value = '';
      }
      this.postListData.courseId = '';
      this.postListData.startDate = '';
      this.postListData.endDate = '';
      this.postListData.state = '';
      this.currentPage = 1;
      this.initData();
    },
    // 获取课程下授权版本函数 coursecategoryGetAll
    getCourseVersion: function getCourseVersion() {
      var _this7 = this;
      this.versionList = [{
        id: 1,
        name: this.authCourseCategoryName
      }];
      this.service.axiosUtils({
        requestName: "coursecategoryGetAll",
        data: {
          courseId: this.drawerData.courseId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.versionList = _this7.versionList.concat(res.data);
          } else {
            _this7.$toast.error(res.message);
          }
        }
      });
    }
  }
};